import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import '../assets/portais.css';
import { fetchPortalPosts, fetchScheduledPortalPosts } from '../requisicoes/db_portais';
import { sites } from "../consts/sites";

function Portais() {
  const [portalInfo, setPortalInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const fetchPortalData = async () => {
      setIsLoading(true);

      const portalData = await Promise.all(
        sites.map(async site => {
          const posts = await fetchPortalPosts(site.url);
          const publishedPosts = posts.filter(post => post.status === 'publish').length;
          const scheduledPosts = await fetchScheduledPortalPosts(site.url);
          const lastPostDate = posts.length > 0 ? posts[0].date : null;

          return {
            name: site.name,
            publishedPosts,
            scheduledPosts,
            lastPostDate,
          };
        })
      );

      setPortalInfo(portalData);
      setIsLoading(false);
    };

    fetchPortalData();
  }, []);

  const sortedPortalInfo = [...portalInfo].sort((a, b) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];

    if (sortDirection === 'asc') {
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    } else {
      if (aValue < bValue) return 1;
      if (aValue > bValue) return -1;
      return 0;
    }
  });

  function formatDate(date) {
    if (!date) {
      return "N/A";
    }

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
  }

  function getCardClassName(date) {
    if (!date) {
      return "";
    }

    const today = new Date();
    const cardDate = new Date(date);
    const timeDifference = today - cardDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    if (daysDifference <= 2) {
      return "recent-post-date";
    } else {
      return "old-post-date";
    }
  }

  return (
    <div className="portais">
      <h1 className="info-header">Informações dos Portais</h1>

      <div className="filters">
        <label>
          Ordenar por:
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="name">Nome</option>
            <option value="publishedPosts">Posts Publicados</option>
            <option value="scheduledPosts">Posts Agendados</option>
            <option value="lastPostDate">Último Post</option>
          </select>
        </label>

        <label>
          Direção:
          <select
            value={sortDirection}
            onChange={(e) => setSortDirection(e.target.value)}
          >
            <option value="asc">Ascendente</option>
            <option value="desc">Descendente</option>
          </select>
        </label>
      </div>

      <input
        className="search-input"
        type="text"
        placeholder="Buscar portais..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />

      <div className="portal-cards">
        {sortedPortalInfo
          .filter((portal) =>
            portal.name.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((portal, index) => (
            <Card key={index} className="portal-card">
              <Card.Body>
                <Card.Title>{portal.name}</Card.Title>
                <Card.Text>Posts Publicados: {portal.publishedPosts}</Card.Text>
                <Card.Text>Posts Agendados: {portal.scheduledPosts.length}</Card.Text>
                <Card.Text className="last-post-date">
                  Último Post: <span className={`date ${getCardClassName(portal.lastPostDate)}`}>{formatDate(portal.lastPostDate)}</span>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
      </div>

      {isLoading && (
        <div className="loading-animation-container">
          <div className="loading-animation"></div>
        </div>
      )}
    </div>
  );
}

export default Portais;
