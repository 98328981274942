import React, { useState, useEffect } from 'react';
import { checkSitesInBatches } from '../requisicoes/db_home';
import '../assets/home.css';

function Home() {
  const [siteStatuses, setSiteStatuses] = useState([]);
  const [offlineCount, setOfflineCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSiteStatuses = async () => {
      const results = await checkSitesInBatches();
      setSiteStatuses(results);

      const offlineSites = results.filter(site => site.status === 'offline').length;
      setOfflineCount(offlineSites);

      setLoading(false);
    };

    fetchSiteStatuses();
  }, []);

  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

  return (
    <div className="home-container">
      <header>
        <h1>Olá, administrador!</h1>
        <p>Hoje é dia: {formattedDate}</p>
      </header>
      <div className="status-container">
  <h2 className="center center-text">Status dos Portais</h2>
        {loading ? (
          <p className='verificando-disponibilidade'>Verificando a disponibilidade dos portais...</p>
        ) : offlineCount === 0 ? (
          <p className='texto-resultado'>✅ Todos os sites estão online</p>
        ) : (
          <div>
            <p className='texto-resultado'>⚠️ {offlineCount} Site{offlineCount > 1 ? 's' : ''} {offlineCount > 1 ? 'estão' : 'está'} offline</p>
            <ul>
              {siteStatuses.filter(site => site.status === 'offline').map((siteInfo, index) => (
                <li key={index}>
                  <strong>{siteInfo.site}:</strong> {siteInfo.url}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
