import { sites } from '../consts/sites';

const CONCURRENT_REQUESTS = 10;

const checkIfWordpressSiteIsOnline = async (url) => {
    try {
        const response = await fetch(`${url}/wp-json/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        if (!response.ok || !data.routes || !data.routes["/wp/v2/posts"]) {
            throw new Error(`Site ${url} não parece ser uma instalação WordPress válida ou está retornando um status inesperado.`);
        }

        return true;
    } catch (error) {
        console.error(`Erro ao verificar o site WordPress ${url}: ${error}`);
        return false;
    }
}

const checkSitesInBatches = async () => {
    let overallResults = [];

    for (let i = 0; i < sites.length; i += CONCURRENT_REQUESTS) {
        const currentBatch = sites.slice(i, i + CONCURRENT_REQUESTS);
        const checkPromises = currentBatch.map(site => checkIfWordpressSiteIsOnline(site.url));

        const results = await Promise.allSettled(checkPromises);
        
        // Processa os resultados deste lote e armazena no array overallResults.
        const batchResults = results.map((result, index) => {
            if (result.status === 'fulfilled' && result.value) {
                return {
                    site: currentBatch[index].name,
                    url: currentBatch[index].url,
                    status: 'online'
                };
            } else {
                return {
                    site: currentBatch[index].name,
                    url: currentBatch[index].url,
                    status: 'offline'
                };
            }
        });

        overallResults = [...overallResults, ...batchResults];
    }
    
    return overallResults;
}

export { checkSitesInBatches };
