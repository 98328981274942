import axios from 'axios';

// Função para obter as informações dos posts de um portal
export async function fetchPortalPosts(siteUrl) {
  try {
    let allPosts = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const timestamp = new Date().getTime(); // Obtém o timestamp atual
      const response = await axios.get(`${siteUrl}/wp-json/wp/v2/posts?_=${timestamp}`, {
        params: {
          per_page: 100,
          page: currentPage,
        },
      });

      console.log(`Fetched ${response.data.length} posts from page ${currentPage}`);
      
      allPosts = [...allPosts, ...response.data];
      totalPages = response.headers['x-wp-totalpages'];
      currentPage++;
    }

    console.log('Finished fetching portal posts:', allPosts);
    return allPosts;
  } catch (error) {
    console.error('Error fetching portal posts:', error);
    return [];
  }
}

// Função para obter o número de posts agendados de um portal
export async function fetchScheduledPortalPosts(siteUrl) {
  try {
    const timestamp = new Date().getTime(); // Obtém o timestamp atual
    const response = await axios.get(`${siteUrl}/wp-json/postsfuturos/v1/info-posts-futuros?_=${timestamp}`);
    
    const scheduledPostsData = response.data.map(item => ({
      title: item.title,
      scheduled_date: item.scheduled_date,
      categories: item.categories,
    }));

    return scheduledPostsData;
  } catch (error) {
    console.error('Error fetching scheduled portal posts:', error);
    return [];
  }
}
