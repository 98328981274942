import React from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as FaIcons from "react-icons/fa";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function GerarPDF({ posts, selectedPeriod, startDate, endDate, totalPosts }) {
  const generatePDF = () => {
    const sortedPosts = posts.sort((a, b) => {
      const dateA = new Date(a.date || a.scheduled_date);
      const dateB = new Date(b.date || b.scheduled_date);
      return dateB - dateA;
    });

    const pdfPosts = sortedPosts.map(post => ({
      title: {
        text: post.title.rendered || post.title,
        link: post.status === "publish" ? post.link : undefined,
        color: post.status === "publish" ? "blue" : "black", // Change color to black for programadas
      },
      selectedPortal: post.selectedPortal,
      status: post.status,
      date: new Date(post.date || post.scheduled_date).toLocaleDateString(),
    }));

    const docDefinition = {
      content: [
        { text: "Relatório de Posts", fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
        { text: `Data Inicial: ${startDate.toLocaleDateString()}`, margin: [0, 0, 0, 5] },
        { text: `Data Final: ${endDate.toLocaleDateString()}`, margin: [0, 0, 0, 0] },
        { text: `Total de Posts: ${totalPosts}`, margin: [0, 5, 0, 10] },
        {
          layout: "lightHorizontalLines",
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*"],
            body: [
              ["Título", "Portal", "Status", "Data"],
              ...pdfPosts.map(post => [
                { text: post.title, link: post.link, color: post.color },
                post.selectedPortal,
                post.status === "publish" ? "Publicado" : "Programado",
                post.date,
              ]),
            ],
          },
        },
      ],
    };

    pdfMake.createPdf(docDefinition).download("relatorio_posts.pdf");
  };

  return (
    <div className='div-botao-pdf' style={{ display: 'flex', justifyContent: 'right', marginTop: '10px', marginRight: '50px' }}>
      <button className="pdf-button" onClick={generatePDF}>
        <FaIcons.FaRegFilePdf /> Gerar PDF
      </button>
    </div>
  );
}

export default GerarPDF;
