import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import '../assets/posts.css';
import { sites } from "../consts/sites";
import { fetchWordPressPosts } from '../requisicoes/db_posts';

function isNewPost(postDate) {
  const currentTime = new Date().getTime();
  const postTime = new Date(postDate).getTime();
  const timeDiff = currentTime - postTime;
  const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000; // 2 dias em milissegundos

  return timeDiff < twoDaysInMilliseconds;
}

function formatDate(dateString) {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function Posts() {
  const [selectedPortal, setSelectedPortal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [lastFetchedPosts, setLastFetchedPosts] = useState([]);

  useEffect(() => {
    if (selectedPortal) {
      setIsLoading(true);
      fetchWordPressPosts(selectedPortal, currentPage)
        .then(({ currentPosts, futureScheduledPosts }) => {
          const newCurrentPosts = currentPosts.filter(post => !lastFetchedPosts.includes(post.id));
          const combinedPosts = [...futureScheduledPosts, ...newCurrentPosts];
          setAllPosts(prevAllPosts => [...prevAllPosts, ...combinedPosts]);
          setHasMorePosts(newCurrentPosts.length > 0);
          setLastFetchedPosts([...lastFetchedPosts, ...newCurrentPosts.map(post => post.id)]);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching WordPress posts:', error);
          setIsLoading(false);
        });
    }
  }, [selectedPortal, currentPage]);

  const loadMorePosts = () => {
    if (!isLoading) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  return (
    <div className="posts">
      <div className="portal-buttons">
        {sites.map(site => (
          <button
            key={site.url}
            className={`portal-button ${selectedPortal === site.url ? "active" : ""}`}
            onClick={() => {
              setSelectedPortal(prevPortal => (prevPortal === site.url ? null : site.url));
              setCurrentPage(1);
              setAllPosts([]);
              setLastFetchedPosts([]);
            }}
          >
            {site.name}
          </button>
        ))}
      </div>
      {selectedPortal && (
        <div className="content-container">
        <div className="card-container">
          {allPosts.map(post => (
            <Card key={post.id} className="custom-card mb-3">
              <Card.Body>
                <Card.Title dangerouslySetInnerHTML={{ __html: post.title.rendered || post.title }}></Card.Title>
                {post.date && (
                  <Card.Text className="mb-2">
                    URL: <a href={post.link} target="_blank" rel="noopener noreferrer">{post.link}</a>
                  </Card.Text>
                )}
                <Card.Subtitle className="mb-2 text-muted">{selectedPortal}</Card.Subtitle>
                {post.categories && (
                  <Card.Text className="mb-2 text-muted">
                    Categorias: {post.categories.join(', ')}
                  </Card.Text>
                )}
              </Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item className={`mb-2 ${isNewPost(post.date) ? "text-success" : "text-danger"}`}>
                  {post.date ? `Publicado em: ${formatDate(post.date)}` : <span style={{ color: 'purple', display: 'block', marginLeft: '0px' }}>Programado para: {formatDate(post.scheduled_date)}</span>}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          ))}
          {isLoading && (
            <div className="loading-animation-container">
              <div className="loading-animation"></div>
            </div>
          )}
        </div>
        </div>
      )}
      {selectedPortal && !isLoading && hasMorePosts && (
        <div className="load-more-container">
          <button className="load-more-button" onClick={loadMorePosts}>
            Carregar mais
          </button>
        </div>
      )}
    </div>
  );
}

export default Posts;