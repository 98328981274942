import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import LoginPage from "./Routes/LoginPage";
import Analises from "./Routes/Analises.js";
import Home from "./Routes/Home.js";
import Portais from "./Routes/Portais.js";
import Relatorios from "./Routes/Relatorios.js";
import Calendario from "./Routes/Calendario.js";
import Posts from "./Routes/Posts.js";
import Navbar from "./components/Navbar.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const AppLayout = ({ isLoggedIn, setIsLoggedIn }) => {
  if (!isLoggedIn) {
    return <LoginPage setIsLoggedIn={setIsLoggedIn} />;
  }

  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const router = createBrowserRouter([
    {
      element: <AppLayout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/posts", element: <Posts /> },
        { path: "analises", element: <Analises /> },
        { path: "portais", element: <Portais /> },
        { path: "calendario", element: <Calendario /> },
        { path: "relatorios", element: <Relatorios /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

createRoot(document.getElementById("root")).render(<App />);
