import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as  AiIcons from "react-icons/ai";
import * as  IoIcons from "react-icons/io";

export const SidebarData = [
    {
        title: "Home",
        path: "/",
        icon: <AiIcons.AiFillHome />,
        cName: "nav-text",
    },
    {
        title: "Posts",
        path: "/posts",
        icon: <AiIcons.AiFillFileText />,
        cName: "nav-text",
    },
    /*{ 
        title: "Analises",
        path: "/analises ",
        icon: <FaIcons.FaAngleUp />,
        cName: "nav-text", 
    }, */
    {
        title: "Portais",
        path: "/portais ",
        icon: <FaIcons.FaAddressBook />,
        cName: "nav-text",
    },
    {
        title: "Calendário",
        path: "/calendario ",
        icon: <FaIcons.FaCalendar />,
        cName: "nav-text",
    },
    /*{
        title: "Relatorios",
        path: "/relatorios ",
        icon: <FaIcons.FaAtom />,
        cName: "nav-text",
    }, */
]