import React, { useState, useEffect } from "react";
import { predefinedCredentials } from "../consts/auth";
import "../assets/LoginPage.css";

const LoginPage = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const storedEmail = localStorage.getItem("rememberMeEmail");
    const storedPassword = localStorage.getItem("rememberMePassword");
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
      tryLogin(storedEmail, storedPassword);
    }
  }, []);

  const tryLogin = (emailToCheck, passwordToCheck) => {
    const foundUser = predefinedCredentials.find(
      (user) => user.email === emailToCheck && user.password === passwordToCheck
    );
    if (foundUser) {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem("rememberMeEmail", email);
      localStorage.setItem("rememberMePassword", password);
    }
  }, [rememberMe, email, password]);

  const handleLogin = () => {
    tryLogin(email, password);
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Painel de Controle</h2>
      <h2 className="login-login">Faça o Login</h2>
      <input
        type="email"
        className="login-input"
        placeholder="Seu email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        className="login-input"
        placeholder="Sua senha"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className="login-checkbox">
        <input
          type="checkbox"
          id="rememberMe"
          checked={rememberMe}
          onChange={() => setRememberMe(!rememberMe)}
        />
        <label htmlFor="rememberMe">Lembrar de mim</label>
      </div>
      <button className="login-button" onClick={handleLogin}>
        Entrar
      </button>
    </div>
  );
};

export default LoginPage;
