import React from "react";

function Relatorios () {
    return(
        <div className="relatorios">
            <h1>Relatórios</h1>
        </div>
    )
}

export default Relatorios