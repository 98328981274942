export const sites = [
  {
    url: 'https://futuroesg.com.br',
    name: 'Futuro ESG',
  },
  {
    url: 'https://revistaempreender.com.br',
    name: 'Revista Empreender',
  },
  {
    url: 'https://grouppublishingbrasil.com.br',
    name: 'Group Publishing',
  },
  {
    url: 'https://todasdatas.com.br',
    name: 'Todas as Datas',
  },
  {
    url: 'https://festivaisdemusica.com.br',
    name: 'Festivais de Música',
  },
  
  
  // Adicione mais URLs e nomes de sites aqui
];