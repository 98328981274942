import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchAllWordPressPosts } from '../requisicoes/db_calendario';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import '../assets/calendario.css';
import GerarPDF from '../requisicoes/gerarPDF';

registerLocale('pt-BR', ptBR);

function Calendario() {
  const [isLoading, setIsLoading] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPeriod, setSelectedPeriod] = useState("custom");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setIsLoading(true);
    fetchAllWordPressPosts(currentPage)
      .then(posts => {
        setAllPosts(prevAllPosts => [...prevAllPosts, ...posts]);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching WordPress posts:', error);
        setIsLoading(false);
      });
  }, [currentPage]);

  const loadMorePosts = () => {
    if (!isLoading) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handleStartDateChange = (date) => {
    setSelectedPeriod("custom");
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedPeriod("custom");
    setEndDate(date);
  };

  const handleCustomPeriod = () => {
    setSelectedPeriod("custom");
  };

  const handlePeriodSelection = (value) => {
    setSelectedPeriod(value);
    if (value === "today") {
      const currentDate = new Date();
      const startOfDay = new Date(currentDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(currentDate);
      endOfDay.setHours(23, 59, 59, 999);
      
      setStartDate(startOfDay);
      setEndDate(endOfDay);
    } else if (value === "thisWeek") {
      const currentDate = new Date();
      setStartDate(startOfWeek(currentDate));
      setEndDate(endOfWeek(currentDate));
    } else if (value === "thisMonth") {
      const currentDate = new Date();
      setStartDate(startOfMonth(currentDate));
      setEndDate(endOfMonth(currentDate));
    }
  };

  const startOfWeek = (date) => {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    return new Date(currentDate.setDate(diff));
  };

  const endOfWeek = (date) => {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? 0 : 6);
    return new Date(currentDate.setDate(diff));
  };

  const startOfMonth = (date) => {
    const currentDate = new Date(date);
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  };

  const endOfMonth = (date) => {
    const currentDate = new Date(date);
    return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  };

  const filteredPosts = allPosts.filter(post => {
    const postDate = new Date(post.date || post.scheduled_date).setHours(0, 0, 0, 0);
    const startTimestamp = startDate.setHours(0, 0, 0, 0);
    const endTimestamp = endDate.setHours(23, 59, 59, 999);
  
    return postDate >= startTimestamp && postDate <= endTimestamp;
  });

  const totalPosts = filteredPosts.length;

  const postsByPortalAndDate = {};
  filteredPosts.forEach(post => {
    if (!postsByPortalAndDate[post.selectedPortal]) {
      postsByPortalAndDate[post.selectedPortal] = [];
    }
    const postDate = new Date(post.date || post.scheduled_date);
    const formattedDate = postDate.toISOString().split('T')[0];
    if (postDate >= startDate && postDate <= endDate) {
      postsByPortalAndDate[post.selectedPortal].push(post);
    }
  });

  return (
    <div className="home">
      <h2 style={{ textAlign: 'center', marginTop: '10px', fontSize: '25px', padding: '10px, 10px, 10px, 10px' }}>Escolha um período para ver os posts publicados/agendados dos portais</h2>
      <div className="date-picker-container">
        <select
          value={selectedPeriod}
          onChange={(e) => handlePeriodSelection(e.target.value)}
        >
          <option value="custom">Selecionar Período</option>
          <option value="today">Hoje</option>
          <option value="thisWeek">Essa semana</option>
          <option value="thisMonth">Esse mês</option>
        </select>
        {selectedPeriod === "custom" && (
          <>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              calendarClassName="custom-calendar"
              locale="pt-BR"
              inputProps={{ readOnly: true }}
            />
            <span>-</span>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              calendarClassName="custom-calendar"
              locale="pt-BR"
              inputProps={{ readOnly: true }}
            />
          </>
        )}
      </div>
      <div className="summary-card" style={{ textAlign: "center" }}>
        <p>Total de Posts:<span style={{marginLeft: '2px'}}>{totalPosts}</span></p>
      </div>
      <GerarPDF posts={filteredPosts} selectedPeriod={selectedPeriod} startDate={startDate} endDate={endDate} totalPosts={totalPosts} />
      <div className="card-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'flex-start' }}>
        {Object.entries(postsByPortalAndDate).map(([portal, posts]) => {
          if (posts.length === 0) {
            return null;
          }

          return (
            <Card key={portal} className="custom-card" style={{ width: '300px', marginBottom: '20px' }}>
              <Card.Header style={{ fontSize: "15px" }}>{portal}</Card.Header>
              <Card.Body style={{ paddingTop: '0px', paddingRight: '5px', paddingLeft: '5px' }}>
                <ul className="posts-list">
                  {posts.map((post, index) => (
                    <li key={index} className="post-title">
                      <span className="bullet">&bull;</span> {post.title.rendered || post.title}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          );
        })}
        <div className="loading-animation-container center-horizontal">
          {isLoading && (
            <div className="loading-animation"></div>
          )}
        </div>
        {filteredPosts.length === 0 && !isLoading && (
          <p className="no-posts-message" style={{ textAlign: 'center' }}>Não há nada para mostrar.</p>
        )}
      </div>
      {isLoading || (currentPage > 1 && (
        <div className="load-more-container">
          <button className="load-more-button" onClick={loadMorePosts}>
            Carregar mais
          </button>
        </div>
      ))}
    </div>
  );
}

export default Calendario;
