import { sites } from "../consts/sites";

export async function fetchAllWordPressPosts(page) {
  try {
    const timestamp = new Date().getTime();
    const perPage = 100;
    const allPosts = [];

    for (const site of sites) {
      const siteUrl = site.url;

      const [postsResponse, scheduledResponse, categoriesResponse] = await Promise.all([
        fetch(`${siteUrl}/wp-json/wp/v2/posts?_=${timestamp}&per_page=${perPage}&page=${page || 1}`),
        fetch(`${siteUrl}/wp-json/postsfuturos/v1/info-posts-futuros?_=${timestamp}`),
        fetch(`${siteUrl}/wp-json/wp/v2/categories`)
      ]);

      const [posts, scheduledPosts, categories] = await Promise.all([
        postsResponse.json(),
        scheduledResponse.json(),
        categoriesResponse.json()
      ]);

      const postsWithCategories = posts.map(post => {
        const postCategories = post.categories.map(categoryId => {
          const category = categories.find(cat => cat.id === categoryId);
          return category ? category.name : '';
        });

        return { ...post, categories: postCategories, selectedPortal: site.name };
      });

      const scheduledPostsWithCategories = scheduledPosts.map(post => {
        return { ...post, selectedPortal: site.name };
      });

      allPosts.push(...postsWithCategories, ...scheduledPostsWithCategories);
    }

    return allPosts;
  } catch (error) {
    console.error('Error fetching WordPress posts:', error);
    return [];
  }
}
