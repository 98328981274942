export async function fetchWordPressPosts(siteUrl, page) {
    try {
      const timestamp = new Date().getTime();
      
      // Busca os posts.
      const response = await fetch(`${siteUrl}/wp-json/wp/v2/posts?_=${timestamp}&per_page=9&page=${page || 1}`);
      const posts = await response.json();
  
      let scheduledPosts = [];
  
      // Se estiver na primeira página, busca os posts agendados.
      if (page === 1) {
        const scheduledResponse = await fetch(`${siteUrl}/wp-json/postsfuturos/v1/info-posts-futuros?_=${timestamp}`);
        scheduledPosts = await scheduledResponse.json();
      }
  
      // Aumenta o limite de categorias buscadas para 100.
      const categoriesResponse = await fetch(`${siteUrl}/wp-json/wp/v2/categories?per_page=100`);
      const categories = await categoriesResponse.json();
  
      // Mapeia as categorias dos posts.
      const postsWithCategories = posts.map(post => {
        const postCategories = post.categories.map(categoryId => {
          const category = categories.find(cat => cat.id === categoryId);
          return category ? category.name : null;
        }).filter(categoryName => categoryName);  // Filtrar quaisquer nomes nulos ou indefinidos.
  
        return { ...post, categories: postCategories };
      });
  
      return {
        currentPosts: postsWithCategories,
        futureScheduledPosts: scheduledPosts
      };
    } catch (error) {
      console.error('Error fetching WordPress posts:', error);
      return {
        currentPosts: [],
        futureScheduledPosts: []
      };
    }
  }
  